import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style-pages.css';
import { BsPerson } from "react-icons/bs";
import logoBrend from '../image/kotak.png'

const ProjectSyra = () => {
    return (
        <div>
            <div className="container pt-3">
                <img className='logoBrand' src={logoBrend} alt='SYRA Ecosystem Logo' />
                <div className='row justify-content-center'>
                    <h1 className='text-center mt-5'>SYRA ECOSYSTEM</h1>
                    <div className='row justify-content-center mt-5'>
                        {/* Maintenance mode message */}
                        <div className='col-12 mx-3 mb-5 border border-danger conten'>
                            <div>
                                <BsPerson className='color-danger' />
                                <p className='fw-bold mt-3 text-center'>MAINTENANCE MODE</p>
                                <p className='mt-3 text-center'>
                                    We are currently undergoing scheduled maintenance. Please check back later.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSyra;
